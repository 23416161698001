/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 20, 2024 */



@font-face {
    font-family: 'ekachon';
    src: url('ekachon-light-webfont.eot');
    src: url('ekachon-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('ekachon-light-webfont.woff2') format('woff2'),
         url('ekachon-light-webfont.woff') format('woff'),
         url('ekachon-light-webfont.ttf') format('truetype'),
         url('ekachon-light-webfont.svg#ekachonlight') format('svg');
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'ekachon';
    src: url('ekachon-bold-webfont.eot');
    src: url('ekachon-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('ekachon-bold-webfont.woff2') format('woff2'),
         url('ekachon-bold-webfont.woff') format('woff'),
         url('ekachon-bold-webfont.ttf') format('truetype'),
         url('ekachon-bold-webfont.svg#ekachonbold') format('svg');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'ekachon';
    src: url('ekachon-regular-webfont.eot');
    src: url('ekachon-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('ekachon-regular-webfont.woff2') format('woff2'),
         url('ekachon-regular-webfont.woff') format('woff'),
         url('ekachon-regular-webfont.ttf') format('truetype'),
         url('ekachon-regular-webfont.svg#ekachonregular') format('svg');
    font-weight: normal;
    font-style: normal;

}